import axios from 'axios';
import i18n from '../i18n';

export const getToken = () => {
  const jsonToken = localStorage.getItem('ccAdmin.currentUser');
  if (jsonToken) {
    return JSON.parse(jsonToken).token;
  }
};

const getAxiosInstance = () => {
  const network = axios.create({
    withCredentials: true,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
    validateStatus: (status) => {
      return status >= 200 && status < 300;
    }
  });

  network.interceptors.response.use(
    (response) => response,
    (error) => {
      const { config, response } = error;

      if (!response) {
        error.message = i18n.t('error.network');
        return Promise.reject(error);
      }

      const isLoginUrl = config.url.endsWith('/login');

      if (response.status === 401 && !isLoginUrl) {
        localStorage.removeItem('ccAdmin.currentUser');
        localStorage.removeItem('modules');
        window.location.href = '/#/login';
      }

      return Promise.reject(response);
    }
  );

  return network;
};

export const getNetwork = getAxiosInstance;