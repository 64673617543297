import React from 'react';
import { Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export const ReportNameSelect = () => {
  const { t } = useTranslation();

  const reportNames = useSelector((state) => state.governmentServiceEducation.selectors.reportNames);
  const reportTitleOptions = reportNames.map((item) => ({
    label: item.name,
    disabled: item.id === 3,
    value: item.id,
  }));

  return (
    <Form.Item
      name='reportId'
      rules={[{ required: true, message: t('error.requiredField') }]}
    >
      <Select placeholder={t('governmentServiceEducation.reportTitle')} options={reportTitleOptions} />
    </Form.Item>
  );
};