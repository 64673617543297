import axios from 'axios';

export const getToken = () => {
  const jsonToken = localStorage.getItem('ccAdmin.currentUser');
  if (jsonToken) {
    return JSON.parse(jsonToken).token;
  }
};

const getAxiosInstance = () => {
  const network = axios.create({
    withCredentials: true,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });
  network.interceptors.response.use((response) => response, (error) => {
    const { config, response } = error;
    const isLoginUrl = config.url.endsWith('/login');
    if (response) {
      if (response.status === 401 && !isLoginUrl) {
        localStorage.removeItem('ccAdmin.currentUser');
        localStorage.removeItem('modules');
        window.location.href = '/#/login';
      }
      return response;
    }
  });
  return network;
};

export const getNetwork = getAxiosInstance;

