import React from 'react';
import { Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export const MonthSelect = () => {
  const { t } = useTranslation();

  const months = useSelector((state) => state.governmentServiceEducation.selectors.months);

  const monthOptions = months.map((item) => ({
    label: item.nameRu,
    value: item.id
  }));
  return (
    <Form.Item
      name='monthId'
      rules={[{ required: true, message: t('error.requiredField') }]}
    >
      <Select placeholder={t('governmentServiceEducation.month')} options={monthOptions} />
    </Form.Item>
  );
};