import {
  SET_GOVERNMENT_SERVICES,
  SET_EVENTS,
  SET_SERVICE_PROVIDERS,
  SET_FORM_SELECTORS_REPORT_DATA,
  SET_TABLE_REPORT_DATA,
  RESET_STATE,
  ADD_FILE, REMOVE_FILE,
  ADD_URLS_AND_CODES,
  SET_VALIDATION_ERRORS,
  CLEAR_VALIDATION_ERRORS,
  INCREMENT_ACTIVE_OPERATIONS,
  DECREMENT_ACTIVE_OPERATIONS
} from '../constants/reportCreation';
import i18n from '../../i18n';
import { message } from 'antd';

const MAX_TOTAL_SIZE_MB = 20;
const MAX_FILES_COUNT = 10;

const initialState = {
  isSelectedFilters: false,
  formSelectorsReportData: {
    reportType: '',
    regionId: null,
    localityId: null,
    organizationId: null,
    monthId: null,
    yearId: null,
  },
  tableReportData: [],
  governmentServices: [],
  events: [],
  serviceProviders: [],
  validationErrors: {},
  activeOperations: 0,
  error: null
};

const addFile = (items, uniqId, file) => {
  return items.map((item) => {
    if (item.uniqId === uniqId) {
      return {
        ...item,
        files: [...(item.files || []), file],
      };
    }

    if (item.children) {
      return {
        ...item,
        children: addFile(item.children, uniqId, file),
      };
    }

    return item;
  });
};

const removeFile = (items, uniqId, fileId) => {
  return items.map((item) => {
    if (item.uniqId === uniqId) {
      return {
        ...item,
        files: item.files.filter((file) => file.id !== fileId),
      };
    }

    if (item.children) {
      return {
        ...item,
        children: removeFile(item.children, uniqId, fileId),
      };
    }

    return item;
  });
};

const addUrlsAndCodes = (items, uniqId, urls, codes) => {
  return items.map((item) => {
    if (item.uniqId === uniqId) {
      return {
        ...item,
        urls,
        codes,
      };
    }

    if (item.children) {
      return {
        ...item,
        children: addUrlsAndCodes(item.children, uniqId, urls, codes),
      };
    }

    return item;
  });
};

const getDataByUniqId = (items, uniqId) => {
  for (let i = 0; i < items.length; i++) {
    if (items[i].uniqId === uniqId) {
      return items[i];
    }

    if (items[i].children) {
      const result = getDataByUniqId(items[i].children, uniqId);
      if (result) return result;
    }
  }
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_GOVERNMENT_SERVICES:
      return { ...state, governmentServices: action.payload };
    case SET_EVENTS:
      return { ...state, events: action.payload };
    case SET_SERVICE_PROVIDERS:
      return { ...state, serviceProviders: action.payload };
    case SET_FORM_SELECTORS_REPORT_DATA:
      return { ...state, formSelectorsReportData: action.payload, isSelectedFilters: true };
    case RESET_STATE:
      return {
        ...state,
        formSelectorsReportData: initialState.formSelectorsReportData,
        isSelectedFilters: false,
        tableReportData: [],
        governmentServices: [],
      };
    case SET_TABLE_REPORT_DATA:
      return { ...state, tableReportData: action.payload };

    // По хорошему нужно обработку ошибок делать в другом месте
    case ADD_FILE: {
      const { uniqId, file } = action.payload;
      const dataByUniqId = getDataByUniqId(state.tableReportData, uniqId);

      const totalSize = dataByUniqId.files.reduce((acc, f) => acc + f.size, 0) + file.size;
      const totalSizeInMB = totalSize / 1024 / 1024;

      if (totalSizeInMB > MAX_TOTAL_SIZE_MB) {
        message.error(i18n.t('governmentServiceEducation.maxSize', { maxSize: MAX_TOTAL_SIZE_MB }));
        return state;
      }

      if (dataByUniqId.files.length >= MAX_FILES_COUNT) {
        message.error(i18n.t('governmentServiceEducation.maxFiles', { maxFiles: MAX_FILES_COUNT }));
        return state;
      }

      if (dataByUniqId.files.length >= dataByUniqId.count) {
        message.error(i18n.t('governmentServiceEducation.maxFiles', { maxFiles: dataByUniqId.count }));
        return state;
      }

      const newState = {
        ...state,
        tableReportData: addFile(state.tableReportData, uniqId, file),
      };

      const currentErrors = state.validationErrors[uniqId] || [];
      return {
        ...newState,
        validationErrors: {
          ...state.validationErrors,
          [uniqId]: currentErrors.filter(e => e !== 'files')
        }
      };
    }
    case REMOVE_FILE: {
      const { uniqId, fileId } = action.payload;
      const currentErrors = state.validationErrors[uniqId] || [];

      return {
        ...state,
        tableReportData: removeFile(state.tableReportData, uniqId, fileId),
        validationErrors: {
          ...state.validationErrors,
          [uniqId]: currentErrors.filter(e => e !== 'excess_files')
        }
      };
    }
    case ADD_URLS_AND_CODES: {
      const { uniqId, urls, codes } = action.payload;
      const newState = {
        ...state,
        tableReportData: addUrlsAndCodes(state.tableReportData, uniqId, urls, codes),
      };

      const currentErrors = state.validationErrors[uniqId] || [];
      const newErrors = currentErrors.filter(e => {
        const found = newState.tableReportData.find(r => r.uniqId === uniqId);
        const foundCount = found ? found.count : 0;

        if (e === 'urls' && urls.length >= foundCount) return false;
        if (e === 'codes' && codes.length >= foundCount) return false;

        return true;
      });

      return {
        ...newState,
        validationErrors: {
          ...state.validationErrors,
          [uniqId]: newErrors
        }
      };
    }
    case SET_VALIDATION_ERRORS:
      return { ...state, validationErrors: action.payload };
    case CLEAR_VALIDATION_ERRORS:
      return { ...state, validationErrors: {} };
    case INCREMENT_ACTIVE_OPERATIONS:
      return { ...state, activeOperations: state.activeOperations + 1 };
    case DECREMENT_ACTIVE_OPERATIONS:
      return { ...state, activeOperations: Math.max(0, state.activeOperations - 1) };
    default:
      return state;
  }
}