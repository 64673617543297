import { Input, InputNumber, Form, Button } from 'antd';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setTableReportData, setValidationErrors } from '../../../redux/actions/reportCreation';
import { useTranslation } from 'react-i18next';

export const useTableColumnsServiceProvidersReport = (onDelete) => {
  const organizations = useSelector((state) => state.governmentServiceEducation.selectors.organizations);
  const tableReportData = useSelector((state) => state.reportCreation.tableReportData);
  const validationErrors = useSelector((state) => state.reportCreation.validationErrors);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const generateNumberInput = (record, field) => {
    const key = `${record.uniqId}-${field}`;
    const hasError = !!validationErrors[key];
    const handleChangeInputNumber = (value) => {
      const newErrors = { ...validationErrors };
      delete newErrors[key];
      dispatch(setValidationErrors(newErrors));

      const updatedTableData = tableReportData.map((item) => {
        if (item.uniqId === record.uniqId) {
          return { ...item, [field]: value !== null ? value : null };
        }
        return item;
      });
      dispatch(setTableReportData(updatedTableData));
    };

    return (
      <Form.Item
        validateStatus={hasError ? 'error' : ''}
        help={hasError && t('error.requiredField')}
        style={{ marginBottom: 0 }}
      >
        <InputNumber
          min={0}
          value={record[field]}
          onChange={handleChangeInputNumber}
        />
      </Form.Item>
    );
  };

  const generateTextInput = (record, field) => {
    const key = `${record.uniqId}-${field}`;
    const hasError = !!validationErrors[key];
    const handleChangeInputText = (e) => {
      const newErrors = { ...validationErrors };
      delete newErrors[key];
      dispatch(setValidationErrors(newErrors));

      const { value } = e.target;
      const updatedTableData = tableReportData.map((item) => {
        if (item.uniqId === record.uniqId) {
          return { ...item, [field]: value };
        }
        return item;
      });
      dispatch(setTableReportData(updatedTableData));
    };

    return (
      <Form.Item
        validateStatus={hasError ? 'error' : ''}
        help={hasError && t('error.requiredField')}
        style={{ marginBottom: 0 }}
      >
        <Input
          type='text'
          value={record[field] || ''}
          onChange={handleChangeInputText}
          placeholder={t('governmentServiceEducation.inputText')}
        />
      </Form.Item>
    );
  };

  return [
    {
      title: t('governmentServiceEducation.serviceProviderName'),
      key: 'organizationId',
      align: 'center',
      render: (_, record) => {
        const organization = organizations.find((item) => item.id === record.organizationId);
        return organization ? organization.nameRu : '';
      },
    },
    {
      title: t('governmentServiceEducation.serviceTypesCount'),
      dataIndex: 'publicServiceTypeAmount',
      align: 'center',
      render: (_, record) => generateNumberInput(record, 'publicServiceTypeAmount'),
    },
    {
      title: t('governmentServiceEducation.employeeFullInfo'),
      dataIndex: 'employeeFullName',
      align: 'center',
      render: (_, record) => generateTextInput(record, 'employeeFullName'),
    },
    {
      title: t('governmentServiceEducation.qualificationYear'),
      dataIndex: 'academyQualificationYear',
      align: 'center',
      render: (_, record) => generateTextInput(record, 'academyQualificationYear'),
    },
    {
      title: t('governmentServiceEducation.contactDetails'),
      dataIndex: 'employeeContactInfo',
      align: 'center',
      render: (_, record) => generateTextInput(record, 'employeeContactInfo'),
    },
    {
      title: t('governmentServiceEducation.techSpecs'),
      key: 'technicalCriteria',
      children: [
        {
          title: t('governmentServiceEducation.baseClock'),
          dataIndex: 'baseClockFrequencyGHz',
          align: 'center',
          render: (_, record) => generateTextInput(record, 'baseClockFrequencyGHz'),
        },
        {
          title: t('governmentServiceEducation.cores'),
          dataIndex: 'coreCountGHz',
          align: 'center',
          render: (_, record) => generateNumberInput(record, 'coreCountGHz'),
        },
        {
          title: t('governmentServiceEducation.ram'),
          dataIndex: 'ramGb',
          align: 'center',
          render: (_, record) => generateNumberInput(record, 'ramGb'),
        },
      ],
    },
    {
      title: t('governmentServiceEducation.actions'),
      key: 'actions',
      align: 'center',
      render: (_, record) => {
        const rowIndex = tableReportData.findIndex(item => item.uniqId === record.uniqId);
        if (rowIndex === 0) return null;

        return (
          <Button
            danger
            onClick={() => onDelete(record.uniqId)}
          >
            {t('governmentServiceEducation.delete')}
          </Button>
        );
      },
    },
  ];
};
