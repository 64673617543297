const Ru = {
  translation: {
    error: {
      doNotHaveAccessToViewThisModule: 'У вас нет доступа к просмотру данного модуля',
      dataEnteredIncorrectly: 'Данные введены некорректно',
      pleaseFillInTheHighlightedFields: 'Пожалуйста заполните выделенные поля',
      errorSavingReport: 'Ошибка при сохранении отчета',
      requiredField: 'Поле обязательно для заполнения',
      readingFile: 'Ошибка чтения файла',
      validatingData: 'Имеются нерешенные ошибки валидации',
    },
    main: {
      SCcontrolPanel: 'Панель управления СЦ',
      exit: 'Выход',
    },
    sideBar: {
      roadRepairPlans: 'План ремонта дорог',
      governmentServicesEducation: 'Гос. услуги образования',
      intersections: 'Пересечения',
      addRoadRepairPlan: 'Добавить план ремонта дорог',
      configuringUsers: 'Настройка пользователей',
      settingUpRoles: 'Настройка ролей',
      settingUpDirectories: 'Настройка справочников',
      makeSchedule: 'Составление расписания',
      userActions: 'Действия пользователей',
    },
    roadRepairPlan: {
      allRoadRepairPlans: 'Все планы ремонта дорог',
      responsible: 'Ответственный',
      categoryOfWork: 'Категория работ',
      plot: 'Участок',
      street: 'Улица',
      contractor: 'Подрядчик',
      hiddenWork: 'Скрытая работа',
      start: 'Начало',
      end: 'Окончание',
      action: 'Действие',
      edit: 'Редактировать',
      delete: 'Удалить',

      search: 'Поиск',
      reset: 'Сбросить',
      exactlyWantToDelete: 'Точно хотите удалить',
      loadingPleaseWait: 'Загрузка, пожалуйста подождите',
      removed: 'Удалено',
      yes: 'Да',
      no: 'Нет',
    },
    intersections: {
      allIntersections: 'Все пересечения',
      act: 'Действие',
      responsible: 'Ответственные',
      categoryOfWork: 'Категория работ',
      street: 'Улица',
      contractor: 'Подрядчик ',
      action: 'Действие',
      delete: 'Удалить',
      edit: 'Редактировать',

      exactlyWantToDelete: 'Вы точно хотите удалить отчет от',
      loadingPleaseWait: 'Загрузка, пожалуйста подождите',
      removed: 'Удалено',
      obligatoryField: 'Обязательное поле',
    },
    addRoadRepairPlan: {
      addingNewRoadRepairPlan: 'Добавление нового плана ремонта дорог',
      crossing: 'Пересечение',
      chooseTheResponsible: 'Выберите ответственного',
      selectCategoryOfWork: 'Выберите категорию работ ',
      replacingTheWaterSupply: 'Замена водопровода ',
      overhaulOfRoads: 'Капитальный ремонт дорог ',
      pipeReconstruction: 'Реконструкция трубы ',
      mediumRoadRepair: 'Средний ремонт дорог ',
      roadMaintenance: 'Текущий ремонт дорог ',
      overlappingTheStreet: 'Перекрытие улицы ',
      objectTypeOnTheMap: 'Тип объекта на карте ',
      coordinates: 'Координаты ',
      label: 'Метка',
      polygon: 'Полигон',
      dateOfThePlannedStartOfWork: 'Дата планируемого начала работ',
      beginningOfWork: 'Начало работ',
      incompleteDateOfThePlannedStartOfWork: 'Не полная дата планируемого начала работ',
      plannedCompletionDate: 'Дата планируемого окончания работ',
      completionOfWork: 'Окончание работ',
      incompleteDateOfThePlannedCompletionOfWork: 'Не полная дата планируемого окончания работ',
      chooseContractor: 'Выберите подрядчика',
      responsibleRepairContacts: 'Контакты ответственного исполнения ремонта',
      selectRepairExecutionStatus: 'Выберите состояние исполнения ремонта',
      inWork: 'В работе',
      performed: 'Выполнено ',
      scheduledRepairs: 'Запланированный ремонт',
      projectCost: 'Стоимость проекта',
      theAmountPlannedFor2021: 'Сумма запланированная на 2021 г.',
      hideThisRoadRepairPlan: 'Скрыть этот план ремонта дорог',
      addTo: 'Добавить',
      yes: 'Да',
      no: 'Нет',
      responsible: 'Ответственный',
      street: 'Улица',
      plot: 'Участок',
      selectCategory: 'Выберите категорию',
      openingOfTheRoadway: 'Вскрытие дорожного полотна',
      warrantyPeriod: 'Гарантийный период',
      length: 'Протяженность',
      whoAcceptedTheWork: 'Кто принял работы',
      comments: 'Комментарии',

      addedBy: 'Добавлено',
      pleaseFillInTheHighlightedFields: 'Пожалуйста заполните выделенные поля',
      loadingPleaseWait: 'Загрузка, пожалуйста подождите',
      pleaseChooseResponsible: 'Пожалуйста выберите ответственного',
      pleaseEnterTheStreet: 'Пожалуйста введите улицу',
      pleaseSelectCategory: 'Пожалуйста выберите категорию',
      selectRoadPlans: 'Выберите планы дорог',
    },
    configuringUsers: {
      allUsers: 'Все пользователи',
      addUser: 'Добавить пользователя',
      fullName: 'ФИО',
      login: 'Логин',
      role: 'Роль',
      action: 'Действие',
      edit: 'Редактировать',
      delete: 'Удалить',
      password: 'Пароль',
      changeUser: 'Изменить пользователя',
      phoneNumber: 'Номер телефона',
      roles: 'Роли',
      addingNewUser: 'Добавление нового пользователя',

      savedBy: 'Сохранено',
      thisUserAlreadyExistsPleaseEnterAnother: 'Такой пользователь уже существует, введите другой',
      pleaseFillInAllFields: 'Пожалуйста заполните все поля',
      user: 'Пользователь',
      established: 'создан',
      removed: 'Удалено',
      loadingPleaseWait: 'Загрузка, пожалуйста подождите',
      regions: 'Регионы',
      region: 'Регион',
      editUser: 'Редактировать пользователя',
    },
    settingUpRoles: {
      allRoles: 'Все роли',
      add: 'Добавить',
      delete: 'Удалить',
      changeCase: 'Изменить кейс',
      addRole: 'Добавить роль',
      code: 'Код',
      description: 'Описание',
      case: 'Кейс',
      action: 'Действие',
      edit: 'Редактировать',
      changingRoles: 'Изменение ролей',

      savedBy: 'Сохранено',
      thisUserAlreadyExistsPleaseEnterAnother: 'Такой пользователь уже существует, введите другой',
      pleaseFillInAllFields: 'Пожалуйста заполните все поля',
      loadingPleaseWait: 'Загрузка, пожалуйста подождите',
      addedBy: 'Добавлено',
    },
    settingUpDirectories: {
      categoryReference: 'Справочник категории',
      contractorsDirectory: 'Справочник подрядчиков',
      name: 'Название',
      responsibleDirectory: 'Справочник ответственных',
      delete: 'Удалить',

      addedBy: 'Добавлено',
      exactlyWantToDelete: 'Точно хотите удалить',
      yes: 'Да',
      no: 'Нет',
      enterNewCategory: 'Введите новую категорию',
      addTo: 'Добавить',
      enterResponsible: 'Введите ответственного',
      removed: 'Удалено',
      plus: 'Добавить',
    },
    settingSchedule: {
      locality: 'Населенный пункт',
      distance: 'Расстояние',
      speed: 'Скорость',
      parking: 'Стоянка',
      arrival: 'Прибытие',
      departure: 'Отправление',
      movementTime: 'Время движения',
      directDirection: 'Прямое направление',
      reverseDirection: 'Обратное направление',
    },
    userActions: {
      eventView: 'Просмотр',
      eventCreate: 'Создание',
      eventUpdate: 'Редактирование',
      eventDelete: 'Удаление',
      eventOpenCase: 'Открытие кейса',
      serviceName: 'Наименование услуг',
      serviceCode: 'Код услуги',
      organization: 'Организация',
      modelOrganization: 'Модель',
      chapter: 'Раздел',
      category: 'Категория',
      subcategory: 'Подкатегория',
      reportTitle: 'Тип отчета',
      region: 'Район',
      year: 'Год',
      month: 'Месяц',
      login: 'Логин',
      dateTime: 'Дата',
      action: 'Действие',
      object: 'Объект',
      caseName: 'Название кейса',
      startDate: 'Начало',
      endDate: 'Конец',
      reset: 'Сбросить',
      choose: 'Выбрать',
    },
    chartStatus: {
      year: 'Год',
      search: 'Найти',
      reset: 'Сбросить',
      organization: 'Организация',
      modelOrganization: 'Модель',
      reportTitle: 'Наименование отчёта',
    },
    gosServiceList: {
      serviceList: 'Реестр услуг',
      eventList: 'Реестр мероприятий',
      charts: 'Отчеты',
      createChart: 'Создать отчет',
      chartStatus: 'Статус отчетов',
      successSavingReport: 'Отчет успешно сохранен',
    },
    governmentServiceEducation: {
      reportTitle: 'Вид отчёта',
      district: 'Район',
      locality: 'Населенный пункт',
      organization: 'Организация',
      year: 'Год',
      month: 'Месяц',
      create: 'Создать',
      clear: 'Сбросить',
      maxSize: 'Общий размер загружаемых файлов не должен превышать {{maxSize}} Мб',
      uploadFiles: 'Загрузить файлы',
      maxFiles: 'Максимальное количество файлов для загрузки: {{maxFiles}}',
      formUrlsAndCodesTitle: 'Форма ввода ссылок и кодов',
      save: 'Сохранить',
      placeholderUrl: 'Введите URL',
      placeholderCode: 'Введите код услуги',
      serviceCode: 'Код услуги',
      buttonUrlsAndCodes: 'Заполнить данные <br />о ссылках и кодах',
      dataSuccessSaved: 'Данные успешно сохранены',
    },
  },
};

export default Ru;
