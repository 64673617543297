import React from 'react';
import { Upload, Button, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { uploadFile, removeFileById } from '../../../redux/asyncActions/reportCreation';

export const GenerateUploadComponent = ({ record }) => {
  const { t } = useTranslation();
  const stateFiles = record.files || [];
  const dispatch = useDispatch();
  const validationErrors = useSelector(state =>
    state.reportCreation.validationErrors[record.uniqId] || []
  );

  const hasFileError = validationErrors.includes('files');

  const hasCountFileError = validationErrors.includes('excess_files');

  const beforeUpload = async (file) => {
    try {
      dispatch(uploadFile(file, record.uniqId));
    } catch (err) {
      message.error(t('error.readingFile'));
      return Upload.LIST_IGNORE;
    }

    return false;
  };

  const handleRemove = (file) => {
    dispatch(removeFileById(file.id, record.uniqId));
  };

  const props = {
    multiple: true,
    fileList: stateFiles,
    beforeUpload,
    onRemove: handleRemove,
    accept: '.pdf,.png,.jpeg',
    showUploadList: true,
    maxCount: record.count,
  };

  return (
    <Upload {...props}>
      <Button
        disabled={stateFiles.length >= record.count}
        icon={<UploadOutlined />}
        danger={hasFileError}
      >
        {t('governmentServiceEducation.uploadFiles')}
      </Button>
      <div>
        {hasCountFileError && (
          <span style={{ color: 'red' }}>{t('governmentServiceEducation.maxFiles', { maxFiles: record.count })}</span>
        )}
      </div>
    </Upload>
  );
};
