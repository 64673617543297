import { setCurrentUser } from '../actions/currentUser';
import { fetchCurrentUser } from '../../services/currentUser';
import { message } from 'antd';

export const loadCurrentUser = () => async (dispatch) => {
  try {
    const result = await fetchCurrentUser();
    const data = result.data;
    dispatch(setCurrentUser(data));
  } catch (error) {
    message.error('Ошибка при загрузке данных');
  }
};