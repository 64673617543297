import { message } from 'antd';
import {
  setGovernmentServices,
  setTableReportData,
  setEvents,
  addFile,
  removeFile,
  incrementActiveOperations,
  decrementActiveOperations,
} from '../actions/reportCreation';
import {
  fetchGovernmentServices,
  fetchSaveReportCreation,
  fetchEventServices,
  fetchUploadFile,
  fetchRemoveFileById,
} from '../../services/reportCreation';
import { groupData } from '../../pages/GovernmentServiceEducation/utils/dataPreparationEvents';

export const loadGovernmentServices = () => async (dispatch) => {
  dispatch(incrementActiveOperations());
  try {
    const response = await fetchGovernmentServices();
    const result = response.data;
    dispatch(setGovernmentServices(result));

    const preparedData = result.map((service) => ({
      serviceId: service.id,
      governmentForCitizenJur: 0,
      governmentForCitizenPhys: 0,
      paperFormJur: 0,
      paperFormPhys: 0,
      paperJustifiedRefusalCount: 0,
      paperUnjustifiedRefusalCount: 0,
      eJustifiedRefusalCount: 0,
      eUnjustifiedRefusalCount: 0,
      totalGovServicesProvidedJur: 0,
      totalGovServicesProvidedPhys: 0,
      eGovernmentPortalJur: 0,
      eGovernmentPortalPhys: 0,
      eServiceProviderInfoNoContactJur: 0,
      eServiceProviderInfoNoContactPhys: 0,
      eFormServiceProviderInfoDirContactManualJur: 0,
      eFormServiceProviderInfoDirContactManualPhys: 0
    }));

    dispatch(setTableReportData(preparedData));
  } catch (error) {
    message.error('Ошибка при загрузке данных');
  } finally {
    dispatch(decrementActiveOperations());
  }
};

export const loadEventServices = () => async (dispatch) => {
  dispatch(incrementActiveOperations());
  try {
    const response = await fetchEventServices();
    const result = response.data;

    dispatch(setEvents(result));
    dispatch(setTableReportData(groupData(result)));

  } catch (error) {
    message.error('Ошибка при загрузке данных');
  } finally {
    dispatch(decrementActiveOperations());
  }
};

export const saveReportCreation = (data) => async (dispatch) => {
  dispatch(incrementActiveOperations());
  try {
    await fetchSaveReportCreation(data);
    return Promise.resolve();
  } catch (error) {
    message.error('Ошибка при сохранении данных');
  } finally {
    dispatch(decrementActiveOperations());
  }
};

export const uploadFile = (file, uniqId) => async (dispatch) => {
  dispatch(incrementActiveOperations());
  try {
    const response = await fetchUploadFile(file);
    const result = response.data;

    dispatch(addFile({
      uniqId,
      file: {
        ...result,
        size: file.size
      }
    }));
  } catch (error) {
    message.error('Ошибка при загрузке файла');
  } finally {
    dispatch(decrementActiveOperations());
  }
};

export const removeFileById = (fileId, uniqId) => async (dispatch) => {
  dispatch(incrementActiveOperations());
  try {
    await fetchRemoveFileById(fileId);
    dispatch(removeFile({ fileId, uniqId }));
  } catch (error) {
    message.error('Ошибка при удалении файла');
  } finally {
    dispatch(decrementActiveOperations());
  }
};