import React, { useEffect } from 'react';
import { FormControls } from './ReportFormControls';
import { Table } from './ReportTable';
import { useSelector, useDispatch } from 'react-redux';
import { loadAllDictionaryGovernmentServiceEducation } from '../../../../redux/asyncActions/governmentServiceEducation';
import { Spin, Alert, Form } from 'antd';

export const ReportBuilder = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { isLoading, error } = useSelector((state) => ({
    isLoading: state.governmentServiceEducation.selectors.isLoading,
    error: state.governmentServiceEducation.selectors.error
  }));

  useEffect(() => {
    dispatch(loadAllDictionaryGovernmentServiceEducation());
  }, [dispatch]);

  if (isLoading) {
    return (
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <Spin size='large' />
      </div>
    );
  }

  if (error) {
    return (
      <Alert
        message="Ошибка"
        description="Не удалось загрузить справочники. Попробуйте обновить страницу."
        type="error"
        showIcon
      />
    );
  }

  return (
    <div>
      <FormControls form={form} />
      <Table resetSelectorsForm={form.resetFields} />
    </div>
  );
};
