import React from 'react';
import { Form, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import { ReportNameSelect } from './ReportNameSelect';
import { DistrictSelect } from './DistrictSelect';
import { LocalitySelect } from './LocalitySelect';
import { OrganizationSelect } from './OrganizationSelect';
import { YearSelect } from './YearSelect';
import { MonthSelect } from './MonthSelect';
import { useDispatch, useSelector } from 'react-redux';
import { setFormSelectorsReportData, resetState } from '../../../../../redux/actions/reportCreation';
import { loadGovernmentServices, loadEventServices } from '../../../../../redux/asyncActions/reportCreation';

export const FormControls = ({ form }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const reportNames = useSelector((state) => state.governmentServiceEducation.selectors.reportNames);

  const handleFinish = (values) => {
    const foundedReport = reportNames.find((item) => item.id === values.reportId);

    if (foundedReport) {
      const reportType = foundedReport.type;

      dispatch(setFormSelectorsReportData({ ...values, reportType }));

      if (reportType === 'EDU_SERVICE_REPORT') {
        dispatch(loadGovernmentServices());
      }

      if (reportType === 'EDU_ACTIVITY_REPORT') {
        dispatch(loadEventServices());
      }

      if (reportType === 'EDU_PROVIDER_REPORT') {
        // dispatch(loadGovernmentServices());
      }
    }
  };

  const handleClear = () => {
    form.resetFields();
    dispatch(resetState());
  };

  return (
    <Form
      form={form}
      onFinish={handleFinish}
      layout='vertical'
      className='container-selectors'
    >
      <ReportNameSelect />
      <DistrictSelect />
      <LocalitySelect />
      <OrganizationSelect />
      <YearSelect />
      <MonthSelect />

      <Form.Item>
        <div className='container-buttons'>
          <Button type='primary' htmlType='submit'>
            {t('governmentServiceEducation.create')}
          </Button>
          <Button type='default' onClick={handleClear}>
            {t('governmentServiceEducation.clear')}
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
