import {
  SET_ACTIVE_TAB,
  SET_REPORT_NAMES,
  SET_DISTRICT,
  SET_LOCALITIES,
  SET_MONTH,
  SET_ORGANIZATIONS,
  SET_YEAR,
  LOAD_DICTIONARIES_ERROR,
  LOAD_DICTIONARIES_SUCCESS,
  LOAD_DICTIONARIES_START
} from '../constants/governmentServiceEducation';

const initialState = {
  activeTab: 'chartBuilder',
  selectors: {
    reportNames: [],
    districts: [],
    localities: [],
    organizations: [],
    years: [],
    months: [],
    isLoading: false,
    error: null
  }
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_ACTIVE_TAB:
      return { ...state, activeTab: payload };
    case SET_REPORT_NAMES:
      return { ...state, selectors: { ...state.selectors, reportNames: payload } };
    case SET_DISTRICT:
      return { ...state, selectors: { ...state.selectors, districts: payload } };
    case SET_LOCALITIES:
      return { ...state, selectors: { ...state.selectors, localities: payload } };
    case SET_ORGANIZATIONS:
      return { ...state, selectors: { ...state.selectors, organizations: payload } };
    case SET_YEAR:
      return { ...state, selectors: { ...state.selectors, years: payload } };
    case SET_MONTH:
      return { ...state, selectors: { ...state.selectors, months: payload } };
    case LOAD_DICTIONARIES_START:
      return {
        ...state,
        selectors: {
          ...state.selectors,
          isLoading: true,
          error: null
        }
      };

    case LOAD_DICTIONARIES_SUCCESS:
      return {
        ...state,
        selectors: {
          ...state.selectors,
          ...action.payload,
          isLoading: false,
          error: null
        }
      };

    case LOAD_DICTIONARIES_ERROR:
      return {
        ...state,
        selectors: {
          ...state.selectors,
          isLoading: false,
          error: action.payload
        }
      };
    default:
      return state;
  }
}