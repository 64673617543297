import { combineReducers } from 'redux';
import user from './user';
import module from './module';
import governmentServiceEducation from './governmentServiceEducation';
import reportCreation from './reportCreation';
import currentUser from './currentUser';

export default combineReducers({
  user,
  module,
  governmentServiceEducation,
  currentUser,
  reportCreation,
});
