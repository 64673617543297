export const physFieldsToSum = [
  'governmentForCitizenPhys',
  'paperFormPhys',
  'eGovernmentPortalPhys',
  'eServiceProviderInfoNoContactPhys',
  'eFormServiceProviderInfoDirContactManualPhys'
];

export const jurFieldsToSum = [
  'governmentForCitizenJur',
  'paperFormJur',
  'eGovernmentPortalJur',
  'eServiceProviderInfoNoContactJur',
  'eFormServiceProviderInfoDirContactManualJur'
];