import { setCurrentUser } from '../actions/currentUser';
import { fetchCurrentUser } from '../../services/currentUser';
import { message } from 'antd';
import i18n from '../../i18n';

export const loadCurrentUser = () => async (dispatch) => {
  try {
    const result = await fetchCurrentUser();
    const data = result.data;
    dispatch(setCurrentUser(data));
  } catch (error) {
    message.error(i18n.t('error.loadingData'));
  }
};