import React from 'react';
import { Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export const LocalitySelect = () => {
  const { t } = useTranslation();
  const currentUser = useSelector((state) => state.currentUser);
  const localities = useSelector((state) => state.governmentServiceEducation.selectors.localities);

  const hasLocalities = currentUser.localities.length > 0;

  const userLocalityOptions = currentUser.localities.map((item) => ({
    label: item.nameRu,
    value: item.id
  }));

  const localityOptions = localities.map((item) => ({
    label: item.nameRu,
    value: item.id
  }));

  return (
    <Form.Item
      name='localityId'
      rules={[{ required: true, message: t('error.requiredField') }]}
    >
      <Select placeholder={t('governmentServiceEducation.locality')} options={
        hasLocalities ? userLocalityOptions : localityOptions
      } />
    </Form.Item>
  );
};