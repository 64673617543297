export const SET_ACTIVE_TAB = '@@GOVERNMENT_SERVICE_EDUCATION/SET_ACTIVE_TAB';
export const SET_SELECTORS = '@@GOVERNMENT_SERVICE_EDUCATION/SET_SELECTORS';
export const SET_REPORT_NAMES = '@@GOVERNMENT_SERVICE_EDUCATION/SET_REPORT_NAMES';
export const SET_DISTRICT = '@@GOVERNMENT_SERVICE_EDUCATION/SET_DISTRICT';
export const SET_LOCALITIES = '@@GOVERNMENT_SERVICE_EDUCATION/SET_LOCALITIES';
export const SET_ORGANIZATIONS = '@@GOVERNMENT_SERVICE_EDUCATION/SET_ORGANIZATIONS';
export const SET_YEAR = '@@GOVERNMENT_SERVICE_EDUCATION/SET_YEAR';
export const SET_MONTH = '@@GOVERNMENT_SERVICE_EDUCATION/SET_MONTH';
export const LOAD_DICTIONARIES_START = '@@GOVERNMENT_SERVICE_EDUCATION/LOAD_DICTIONARIES_START';
export const LOAD_DICTIONARIES_SUCCESS = '@@GOVERNMENT_SERVICE_EDUCATION/LOAD_DICTIONARIES_SUCCESS';
export const LOAD_DICTIONARIES_ERROR = '@@GOVERNMENT_SERVICE_EDUCATION/LOAD_DICTIONARIES_ERROR';