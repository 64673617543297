import { message } from 'antd';
import {
  setDistrict,
  setLocalities,
  setMonth,
  setOrganizations,
  setReportNames,
  setYear,
  loadDictionariesStart,
  loadDictionariesSuccess,
  loadDictionariesError
} from '../actions/governmentServiceEducation';
import {
  fetchDistricts,
  fetchLocalities,
  fetchOrganizations,
  fetchReportNames,
  fetchYears,
  fetchMonths
} from '../../services/governmentServiceEducation';

export const loadReportNames = () => async (dispatch) => {
  try {
    const response = await fetchReportNames();
    const result = response.data;
    dispatch(setReportNames(result));
  } catch (error) {
    message.error('Ошибка при загрузке данных');
  }
};

export const loadDistricts = () => async (dispatch) => {
  try {
    const response = await fetchDistricts();
    const result = response.data;
    dispatch(setDistrict(result));
  } catch (error) {
    message.error('Ошибка при загрузке данных');
  }
};

export const loadLocalities = () => async (dispatch) => {
  try {
    const response = await fetchLocalities();
    const result = response.data;
    dispatch(setLocalities(result));
  } catch (error) {
    message.error('Ошибка при загрузке данных');
  }
};

export const loadOrganizations = () => async (dispatch) => {
  try {
    const response = await fetchOrganizations();
    const result = response.data;
    dispatch(setOrganizations(result));
  } catch (error) {
    message.error('Ошибка при загрузке данных');
  }
};

export const loadYears = () => async (dispatch) => {
  try {
    const response = await fetchYears();
    const result = response.data;
    dispatch(setYear(result));
  } catch (error) {
    message.error('Ошибка при загрузке данных');
  }
};

export const loadMonths = () => async (dispatch) => {
  try {
    const response = await fetchMonths();
    const result = response.data;
    dispatch(setMonth(result));
  } catch (error) {
    message.error('Ошибка при загрузке данных');
  }
};

export const loadAllDictionaryGovernmentServiceEducation = () => async (dispatch) => {
  try {
    dispatch(loadDictionariesStart());
    const [
      responseReportNames,
      responseDistricts,
      responseLocalities,
      responseOrganizations,
      responseYears,
      responseMonths
    ] = await Promise.all([
      fetchReportNames(),
      fetchDistricts(),
      fetchLocalities(),
      fetchOrganizations(),
      fetchYears(),
      fetchMonths()
    ]);

    dispatch(loadDictionariesSuccess({
      reportNames: responseReportNames.data,
      districts: responseDistricts.data,
      localities: responseLocalities.data,
      organizations: responseOrganizations.data,
      years: responseYears.data,
      months: responseMonths.data
    }));
  } catch (error) {
    dispatch(loadDictionariesError(error));
  }
};