import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Input } from 'antd';
import { useTranslation, Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { addUrlsAndCodes } from '../../../../redux/actions/reportCreation';
import './styles.scss';


export const FillingUrlsAndCodes = ({ record }) => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [serviceUrls, setServiceUrls] = useState([]);
  const [serviceCodes, setServiceCodes] = useState([]);
  const validationErrors = useSelector(state =>
    state.reportCreation.validationErrors[record.uniqId] || []
  );

  const hasUrlError = validationErrors.includes('urls');
  const hasCodeError = validationErrors.includes('codes');
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // Костыль, нужно избавиться от управления состоянием через форму и использовать redux
  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record.count]);


  if (Array.isArray(record.children) && record.children.length) {
    return null;
  }

  const handleClick = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleFinish = (values) => {
    const listKeyCode = Object.keys(values).filter((key) => key.includes('serviceCode'));
    const listKeyUrl = Object.keys(values).filter((key) => key.includes('serviceUrl'));
    const codes = listKeyCode.map((key) => values[key]);
    const urls = listKeyUrl.map((key) => values[key]);
    dispatch(addUrlsAndCodes({ uniqId: record.uniqId, urls, codes }));
    setIsModalOpen(false);
  };

  const isVisible = record.isServiceCodeRequired || record.isUrlRequired;

  return (
    <div>
      {(record.urls.length > 0 || record.codes.length > 0 || (isVisible && record.count > 0)) &&
        <Button onClick={handleClick} danger={hasUrlError || hasCodeError} type="link">
          <Trans i18nKey="governmentServiceEducation.buttonUrlsAndCodes" />
        </Button>
      }
      <Modal
        title={t('governmentServiceEducation.formUrlsAndCodesTitle')}
        open={isModalOpen}
        onOk={() => form.submit()}
        onCancel={handleCancel}
        okText={t('governmentServiceEducation.save')}
      >
        <Form className='form' layout="vertical" form={form} onFinish={handleFinish}>
          {record.isUrlRequired && (
            <div className='form__group'>
              {Array.from({ length: record.count }).map((_, index) => (
                <Form.Item
                  key={index}
                  label="URL"
                  name={`serviceUrl-${index}`}
                  rules={[{ required: true, message: t('error.requiredField') }]}
                >
                  <Input
                    value={serviceUrls[index]}
                    onChange={(e) => {
                      const newServiceUrls = [...serviceUrls];
                      newServiceUrls[index] = e.target.value;
                      setServiceUrls(newServiceUrls);
                    }}
                    placeholder={t('governmentServiceEducation.placeholderUrl')}
                  />
                </Form.Item>
              ))}
            </div>
          )}
          {record.isServiceCodeRequired && (
            <div className='form__group'>
              {Array.from({ length: record.count }).map((_, index) => (
                <Form.Item
                  key={index}
                  label={t('governmentServiceEducation.serviceCode')}
                  name={`serviceCode-${index}`}
                  rules={[{ required: true, message: t('error.requiredField') }]}
                >
                  <Input
                    value={serviceUrls[index]}
                    onChange={(e) => {
                      const newServiceCodes = [...serviceCodes];
                      newServiceCodes[index] = e.target.value;
                      setServiceCodes(newServiceCodes);
                    }}
                    placeholder={t('governmentServiceEducation.placeholderCode')}
                  />
                </Form.Item>
              ))}
            </div>
          )}
        </Form>
      </Modal>
    </div>
  );
};