const Kz = {
  translation: {
    error: {
      doNotHaveAccessToViewThisModule: 'Сізде бұл модульді көруге рұқсат жоқ',
      dataEnteredIncorrectly: 'Деректер қате енгізілді',
      pleaseFillInTheHighlightedFields: 'Көрсетілген өрістерді толтырыңыз',
      errorSavingReport: 'Есепті сақтау кезінде қате пайда болды',
      requiredField: 'Бұл өрісті толтыру міндетті',
      readingFile: 'Файлды оқу қатесі',
      validatingData: 'Шешілмеген тексеру қателері бар',
      loadingData: 'Деректерді жүктеу кезінде қате пайда болды',
      reportAlreadyExists: 'Көрсетілген параметрлер бойынша есеп бар',
      savingData: 'Деректерді сақтау кезінде қате пайда болды',
      loadingFile: 'Файлды жүктеу кезінде қате пайда болды',
      deleteFile: 'Файлды жою кезінде қате пайда болды',
      network: 'Желі қатесі. Интернет байланысын тексеріңіз',
    },
    main: {
      SCcontrolPanel: 'СК басқару панелі',
      exit: 'Шығу',
    },
    sideBar: {
      roadRepairPlans: 'Жолдарды жөндеу жоспары',
      governmentServicesEducation: 'Мемлекеттік білім беру қызметтері',
      intersections: 'Қиылысу',
      addRoadRepairPlan: 'Жолды жөндеу жоспарын қосу',
      configuringUsers: 'Пайдаланушыны қалыпқа келтіру',
      settingUpRoles: 'Рөлдерді қалыпқа келтіру',
      settingUpDirectories: 'Анықтамалықтарды қалыпқа келтіру',
      userActions: 'Пайдаланушы әрекеттері',
    },
    roadRepairPlan: {
      allRoadRepairPlans: 'Жол жөндеудің барлық жоспарлары',
      responsible: 'Жауапты',
      categoryOfWork: 'Жұмыс санаты',
      plot: 'Учаскесі',
      street: 'Көше',
      contractor: 'Мердігер',
      hiddenWork: 'Жасырын жұмыс',
      start: 'Басталуы',
      end: 'Аяқталуы',
      action: 'Әрекет',
      edit: 'Редакциялау',
      delete: 'Өшіру',

      search: 'Іздеу',
      reset: 'Қалпына келтіру',
      exactlyWantToDelete: 'Дәл жою керек',
      loadingPleaseWait: 'Жүктелуде, күтіңіз',
      removed: 'Жойылды',
      yes: 'Иә',
      no: 'Жоқ',
    },
    intersections: {
      allIntersections: 'Барлық қиылыстар',
      act: 'Әрекет',
      responsible: 'Жауапты',
      categoryOfWork: 'Жұмыс санаты',
      street: 'Көше',
      contractor: 'Мердігер',
      action: 'Әрекет',
      delete: 'Өшіру',
      edit: 'Редакциялау',

      exactlyWantToDelete: 'Есепті жойғыңыз келетініне сенімдісіз бе',
      loadingPleaseWait: 'Жүктелуде, күтіңіз',
      removed: 'Жойылды',
      obligatoryField: 'Міндетті өріс',
    },
    addRoadRepairPlan: {
      addingNewRoadRepairPlan: 'Жаңа жол жөндеу жоспарын қосу',
      crossing: 'Қиылысу',
      chooseTheResponsible: 'Жауапты адамды таңдау',
      selectCategoryOfWork: 'Жұмыс санатын таңдау',
      replacingTheWaterSupply: 'Су құбырын ауыстыру',
      overhaulOfRoads: 'Жолдарды күрделі жөндеу',
      pipeReconstruction: 'Құбырды қайта жөндеу',
      mediumRoadRepair: 'Жолдарды орташа жөндеу',
      roadMaintenance: 'Жолдарды ағымдағы жөндеу',
      overlappingTheStreet: 'Көшені жабу',
      objectTypeOnTheMap: 'Картадағы нысан түрі',
      coordinates: 'Координаттары',
      label: 'Белгі',
      polygon: 'Полигон',
      dateOfThePlannedStartOfWork: 'Жұмыстардың жоспарланған басталу күні',
      beginningOfWork: 'Жұмыстың басталуы',
      incompleteDateOfThePlannedStartOfWork: 'Жұмыстардың жоспарланған бастаудың нақыт',
      plannedCompletionDate: 'Жұмыстарды жоспарлы аяқтау күні',
      completionOfWork: 'Жұмыстарды аяқтау',
      incompleteDateOfThePlannedCompletionOfWork: 'Жұмыстарды жоспарлы аяқтаудың нықты емес күні',
      chooseContractor: 'Мердігерді таңдаңыз',
      responsibleRepairContacts: 'Жөндеуге жауапты тұлғаның байланыс деректері',
      selectRepairExecutionStatus: 'Жұмысты орындау түрін таңдаңыз',
      inWork: 'Жұмыста',
      performed: 'Орындалды',
      scheduledRepairs: 'Жоспарланған жұмыс',
      projectCost: 'Жобаның құны',
      theAmountPlannedFor2021: '2021 жылға жоспарланған сома',
      hideThisRoadRepairPlan: 'Жолдарды жөндеу жоспарының кезеңдерін жасыру',
      addTo: 'Қосу',
      yes: 'Иә',
      no: 'Жоқ',
      responsible: 'Жауапты',
      street: 'Көше',
      plot: 'Учаскесі',
      selectCategory: 'Саннаты таңдаңыз',
      openingOfTheRoadway: 'Жол төсемін ашу',
      warrantyPeriod: 'Кепілдік кезеңі',
      length: 'Ұзындығы',
      whoAcceptedTheWork: 'Жұмысты кім қабылдады',
      comments: 'Пікірлер',

      addedBy: 'Қосқан',
      pleaseFillInTheHighlightedFields: 'Бөлектелген өрістерді толтырыңыз',
      loadingPleaseWait: 'Жүктелуде, күтіңіз',
      pleaseChooseResponsible: 'Жауапты адамды таңдаңыз',
      pleaseEnterTheStreet: 'Пожалуйста, көшеге кіріңіз',
      pleaseSelectCategory: 'Санатты таңдаңыз',
      selectRoadPlans: 'Жол жоспарларын таңдаңыз',
    },
    configuringUsers: {
      allUsers: 'Барлық пайдаланушылар',
      addUser: 'Пайдаланушыны қосу',
      fullName: 'Аты-жөні',
      login: 'Кіру',
      role: 'Рөлі',
      action: 'Әрекет',
      edit: 'Редакциялау',
      delete: 'Өшіру',
      password: 'Пароль',
      changeUser: 'Пайдаланушыны өзгерту',
      phoneNumber: 'Телефон нөмірі',
      roles: 'Рөлдер',
      addingNewUser: 'Жаңа пайдаланушыны қосу',

      savedBy: 'Сақталған',
      thisUserAlreadyExists: 'Бұл қолданушы бұрыннан бар, басқасын енгізіңіз',
      pleaseEnterAnotherPleaseFillInAllFields: 'Барлық өрістерді толтырыңыз',
      user: 'Пайдаланушы',
      established: 'құрылған',
      removed: 'Жойылды',
      loadingPleaseWait: 'Жүктелуде, күтіңіз',
      regions: 'Аймақтар',
      region: 'Аймақ',
      editUser: 'Пайдаланушыны өңдеу',
    },
    settingUpRoles: {
      allRoles: 'Барлық рөлдер',
      add: 'Қосу',
      delete: 'Өшіру',
      changeCase: 'Істі өзгерту',
      addRole: 'Рөл қосу ',
      code: 'Код ',
      description: 'Сипаттамасы',
      case: 'Кейс',
      action: 'Әрекет',
      edit: 'Редакциялау',
      changingRoles: 'Рөлдерді өзгерту',

      savedBy: 'Сақталған',
      thisUserAlreadyExists: 'Бұл қолданушы бұрыннан бар, басқасын енгізіңіз',
      pleaseEnterAnotherPleaseFillInAllFields: 'Барлық өрістерді толтырыңыз',
      loadingPleaseWait: 'Жүктелуде, күтіңіз',
      addedBy: 'Қосқан',
    },
    settingUpDirectories: {
      categoryReference: 'Санаттар анықтамалығы',
      contractorsDirectory: 'Мердігерлердің анықтамалығы',
      name: 'Атауы',
      responsibleDirectory: 'Жауапты тұлғалардың анықтамалығы',
      delete: 'Өшіру',

      addedBy: 'Қосқан',
      exactlyWantToDelete: 'Дәл жою керек',
      yes: 'Иә',
      no: 'Жоқ',
      enterNewCategory: 'Жаңа санатты енгізіңіз',
      addTo: 'Қосу',
      enterResponsible: 'Жауапты енгізіңіз',
      removed: 'Жойылды',
      plus: 'Қосу',
    },
    userActions: {
      eventView: 'Қарау',
      eventCreate: 'Құрастыру',
      eventUpdate: 'Түзету',
      eventDelete: 'Жою',
      eventOpenCase: 'Кейстi ашу',
      serviceName: 'Қызмет аты',
      serviceCode: 'Қызмет коды',
      organization: 'Ұйым',
      modelOrganization: 'Модель',
      chapter: 'Бөлім',
      category: 'Санат',
      subcategory: 'Ішкі санат',
      reportTitle: 'Есеп түрі',
      region: 'Аудан',
      year: 'Жыл',
      month: 'Ай',
      login: 'Логин',
      dateTime: 'Күні',
      action: 'Әрекет',
      object: 'Объект',
      caseName: 'Кейс аты',
      startDate: 'Басы',
      endDate: 'Соңы',
      reset: 'Қалпына келтіру',
      choose: 'Таңдау',
    },
    chartStatus: {
      year: 'Жыл',
      search: 'Іздеу',
      reset: 'Қалпына келтіру',
      organization: 'Ұйым',
      modelOrganization: 'Модель',
      reportTitle: 'Есеп аты',
    },
    gosServiceList: {
      serviceList: 'Қызметтер тізімі',
      eventList: 'Іс-шаралар тізімі',
      charts: 'Есептер',
      createChart: 'Есепті құрастыру',
      chartStatus: 'Есептің статусы',
      successSavingReport: 'Есеп сәтті сақталды',
    },
    governmentServiceEducation: {
      reportTitle: 'Есеп түрі',
      district: 'Аудан',
      locality: 'Елді мекен',
      organization: 'Ұйым',
      year: 'Жыл',
      month: 'Ай',
      create: 'Жасау',
      clear: 'Тазалау',
      maxSize: 'Жүктеп салынған файлдардың жалпы өлшемі {{maxSize}} МБ аспауы керек',
      uploadFiles: 'Файлдарды жүктеу',
      maxFiles: 'Жүктеп салу үшін қажетті файлдар саны: {{maxFiles}}',
      formUrlsAndCodesTitle: 'Сілтемелер мен кодтарды енгізуге арналған пішін',
      save: 'Сақтау',
      placeholderUrl: 'URL мекенжайын енгізіңіз',
      placeholderCode: 'Қызмет кодын енгізіңіз',
      serviceCode: 'Қызмет коды',
      buttonUrlsAndCodes: 'Сілтемелер мен кодтар <br />туралы деректерді толтырыңыз',
      dataSuccessSaved: 'Деректер сәтті сақталды',
      addRow: 'Жолды қосу',
      delete: 'Жою',
      inputText: 'Мәтінді енгізіңіз',
      serialNumber: '№ п/п',
      activities: 'Орындалатын шаралар',
      quantity: 'Саны',
      attachments: 'Тіркелген файлдар',
      other: 'Басқа',
      governmentServicesName: 'Мемлекеттік қызметтердің атауы',
      totalServicesProvided: 'БАРЛЫҒЫ (көрсетілген мемлекеттік қызметтер саны)',
      individuals: 'жеке тұлға',
      legalEntities: 'заңды тұлға',
      providedThrough: 'тікелей көрсетілген:',
      governmentCorporation: '«Азаматтарға арналған Үкімет» МК',
      eGovernmentPortal: 'ЭЛЕКТРОНДЫҚ ҮКІМЕТ ПОРТАЛЫ',
      governmentAgency: 'мемлекеттік орган:',
      paperForm: 'қағаз түрінде',
      eServiceNoContact: `электрондық нысанда көрсетілетін қызметті берушінің ақпараттық жүйелері
      арқылы көрсетілетін қызметті алушымен тікелей байланыссыз (www.egov.kz, www.elicense.kz қоспағанда)`,
      eServiceWithContact: `көрсетілетін қызметті алушымен тікелей байланысу және өтінішті ақпараттық жүйеге қолмен енгізу
      арқылы көрсетілетін қызметті берушініңақпараттық жүйелері арқылы
      электрондық нысанда (www.egov.kz, www.elicense.kz қоспағанда)`,
      justifiedRefusals: 'Негізді қабылданбағандар саны',
      unjustifiedRefusals: 'Негізсіз қабылданбағандар саны',
      electronicForm: 'электронды түрі',
      paperFormShort: 'қағаз түрі',
      serviceProviderName: 'Қызмет көрсетушінің атауы',
      serviceTypesCount: 'Мемлекеттік қызмет түрлерінің саны',
      employeeFullInfo: `Мемлекеттік қызмет көрсетуге қатысатын көрсетілетін қызметті берушілер
      қызметкерлерінің Т.А.Ә., лауазымы (толық атын (бар болса әкесінің атын), "менеджерді" немесе "маманды" көрсетіңіз)`,
      qualificationYear: 'Академия филиалында мемлекеттік қызмет көрсету саласындағы біліктілігін арттыру курсы',
      contactDetails: 'Қызмет провайдерлерінің байланыс деректері (аймақ коды көрсетілген телефон нөмірі)',
      techSpecs: 'Компьютердің техникалық жабдықтарының критерийлері, процессор деректері',
      baseClock: 'негізгі такт жиілігі (** ГГц)',
      cores: 'ядролар саны',
      ram: 'жедел жад көлемі (ГБ)',
      actions: 'Әрекеттер'
    },
  },
};

export default Kz;
