import React from 'react';
import { Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export const DistrictSelect = () => {
  const { t } = useTranslation();
  const currentUser = useSelector((state) => state.currentUser);
  const districts = useSelector((state) => state.governmentServiceEducation.selectors.districts);

  const hasDistricts = currentUser.districts.length > 0;

  const userDistrictOptions = currentUser.districts.map((item) => ({
    label: item.nameRu,
    value: item.id
  }));

  const districtOptions = districts.map((item) => ({
    label: item.nameRu,
    value: item.id
  }));

  return (
    <Form.Item
      name='regionId'
      rules={[{ required: true, message: t('error.requiredField') }]}
    >
      <Select
        placeholder={t('governmentServiceEducation.district')} options={
          hasDistricts ? userDistrictOptions : districtOptions
        } />
    </Form.Item>
  );
};