import React from 'react';
import { Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export const YearSelect = () => {
  const { t } = useTranslation();

  const years = useSelector((state) => state.governmentServiceEducation.selectors.years);
  const yearOptions = years.map((item) => ({
    label: item.year,
    value: item.id
  }));

  return (
    <Form.Item
      name='yearId'
      rules={[{ required: true, message: t('error.requiredField') }]}
    >
      <Select placeholder={t('governmentServiceEducation.year')} options={yearOptions} />
    </Form.Item>
  );
};