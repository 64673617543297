import { SET_USER } from '../actions/type';
import jwt_decode from 'jwt-decode';

var token = JSON.parse(localStorage.getItem('ccAdmin.currentUser'));
var decodedUserId = token && jwt_decode(token.token).userId;

const initialState = { userId: decodedUserId };

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_USER:
      return { userId: payload };
    default:
      return state;
  }
}
