import React from 'react';
import { InputNumber } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setTableReportData } from '../../../redux/actions/reportCreation';
import { GenerateUploadComponent } from '../components/GenerateUploadComponents';
import { FillingUrlsAndCodes } from '../components/FillingUrlsAndCodes';
import { useTranslation } from 'react-i18next';

const updateNestedDataWithSums = (data, record, field, value) => {
  return data.map((item) => {
    if (item.uniqId === record.uniqId) {
      return {
        ...item,
        urls: [],
        codes: [],
        [field]: value !== null ? value : 0,
      };
    }

    if (Array.isArray(item.children) && item.children.length > 0) {
      const updatedChildren = updateNestedDataWithSums(item.children, record, field, value);

      const updatedCount = updatedChildren.reduce((sum, child) => sum + (child.count || 0), 0);

      return {
        ...item,
        children: updatedChildren,
        count: updatedCount,
      };
    }

    return item;
  });
};


export const useTableColumnsEventsReport = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tableReportData = useSelector((state) => state.reportCreation.tableReportData);
  const generateFieldForm = (record, field) => {
    const handleChangeInputNumber = (value) => {
      const updatedTableData = updateNestedDataWithSums(
        tableReportData,
        record,
        field,
        value
      );
      dispatch(setTableReportData(updatedTableData));
    };

    return (
      <InputNumber
        min={0}
        value={record[field]}
        onChange={handleChangeInputNumber}
      />
    );
  };

  return [
    {
      title: t('governmentServiceEducation.serialNumber'),
      dataIndex: 'uniqId',
      width: 110,
    },
    {
      title: t('governmentServiceEducation.activities'),
      dataIndex: 'name',
      width: 500,
    },
    {
      title: t('governmentServiceEducation.quantity'),
      key: 'count',
      align: 'center',
      width: 110,
      render: (_, record) =>
        Array.isArray(record.children) && record.children.length
          ? record.count
          : generateFieldForm(record, 'count'),
    },
    {
      title: t('governmentServiceEducation.attachments'),
      key: 'files',
      align: 'center',
      width: 110,
      render: (_, record) => {
        const isNotEditableLevel = Array.isArray(record.children) && record.children.length;
        if (isNotEditableLevel) {
          return <GenerateUploadComponent record={record} />;
        }

        const isVisible = record.files.length > 0 || (record.isAttachmentRequired && record.count > 0);

        return isVisible && <GenerateUploadComponent record={record} />;
      },
    },
    {
      title: t('governmentServiceEducation.other'),
      key: 'urlsAndCodes',
      align: 'center',
      width: 110,
      render: (_, record) => <FillingUrlsAndCodes record={record} />
    },
  ];
};