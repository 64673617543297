import React, { Component } from 'react';
import { Form, Input, Button, message, Modal, Spin } from 'antd';
import { API_AUTH, API_ROOT } from '../../config';
import { Redirect } from 'react-router-dom';
import Icon from '@ant-design/icons';
import { setUser } from '../../redux/actions/user';
import { setModules } from '../../redux/actions/module';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import bcrypt from 'bcryptjs';
import { getNetwork } from '../../utils/getNetwork';
import styles from './SingleLayout.module.scss';
import { setCurrentUser } from '../../redux/actions/currentUser';
class SingleLayout extends Component {
  constructor() {
    super();
    this.state = {
      redirect: false,
      login: '',
      password: '',
      roleId: null,
      isModalOpen: true,
      reportAbsentMonths: {},
      isLoading: true,
      isChecked: false,
    };
  }

  sendLogin = () => {
    const { login, password } = this.state;
    let params2 = { login, password };

    let header = { headers: { 'Content-Type': 'application/json' } };
    getNetwork().post(API_AUTH + '/login', params2, header).then((res) => {
      if (res && res.status === 200) {
        const currentUser = res.data;
        localStorage.setItem(
          'ccAdmin.currentUser',
          JSON.stringify(currentUser)
        );

        getNetwork()
          .get(API_AUTH + '/users/current', {
            headers: {
              Authorization: `Bearer ${res.data.token}`,
            },
          })
          .then((res) => {
            this.getCasesRoles(res);
            this.getAbsentReports(res);

            const data = res.data;
            this.props.dispatch(setUser(data.id));
            this.props.dispatch(setCurrentUser(data));

            if (data.role.id) {
              const currentUserRoleId = data.role.id;
              this.setState({ roleId: currentUserRoleId });
            }

          })
          .catch(() => { });

        setTimeout(() => {
          this.setState({ redirect: true });
        }, 200);
      } else {
        message.error('Пользователь или пароль не правильный');
      }
    });
  };

  inputOnChange = (key, e) => {
    if (key === 'login') this.setState({ [key]: e.target.value });
    else this.setState({ [key]: e.target.value });
  };

  getCasesRoles = (res) => {
    getNetwork()
      .get(API_AUTH + '/get/casesroles/' + res.data.role.id, {
        headers: res.config.headers,
      })
      .then((res) => {
        let crypted = [];
        const casesRoles = res.data.map((e) => '/' + e.casesEntity.code);
        casesRoles.push('/');
        casesRoles.push('');
        casesRoles.forEach((caseItem) => {
          const hash = bcrypt.hashSync(caseItem, 2);
          crypted.push(hash);
        });
        localStorage.setItem('modules', JSON.stringify(crypted));
        this.props.dispatch(setModules(crypted));
      })
      .catch(() => { });
  };

  getAbsentReports = (response) => {
    getNetwork()
      .get(API_ROOT + '/notif/report', {
        headers: response.config.headers,
      })
      .then(res => {
        if (res && res.status === 200) {
          this.setState({ reportAbsentMonths: res.data });
        }
      })
      .finally(() => this.setState({ isLoading: false }));
  }

  render() {
    let { t } = this.props;
    const { roleId, isModalOpen, reportAbsentMonths, isChecked } = this.state;
    const { report1AbsentMonths, report2AbsentMonths, report3AbsentMonths } = reportAbsentMonths;

    if (this.props.user.userId && this.props.module.modules && localStorage.getItem('ccAdmin.currentUser')) {
      if (roleId === 7 && !isChecked) {
        return (
          <Spin tip={t('intersections.loadingPleaseWait') + '...'} spinning={this.state.isLoading}>
            <Modal
              title="Уведомления АРМ Госуслуги"
              open={isModalOpen}
              onCancel={() => {
                this.setState({ isModalOpen: false });
              }}
              footer={false}
            >
              {report1AbsentMonths &&
                !report1AbsentMonths.length &&
                report2AbsentMonths &&
                !report2AbsentMonths.length &&
                report3AbsentMonths &&
                !report3AbsentMonths.length ? (
                <div>
                  <h4 className={styles.modalTitle}>
                    {'По государственному органу (Управление информатизации), имеются все необходимые отчеты в АРМ Госуслуги за текущий год.'}
                  </h4>
                  <p>Создание новых отчетов на текущий момент не требуется!</p>
                </div>
              ) : (
                <div>
                  <h4 className={styles.modalTitle}>
                    {'По государственному органу (Управление информатизации), отсутствуют следующие отчеты за текущий год: '}
                  </h4>
                  <p>
                    {'- Отчет о работе по оказанным государственным услугам физическим и юридическим лицам: '}
                    <span className={styles.modalMonths}>
                      {report1AbsentMonths ? report1AbsentMonths.join(', ') : '-'}
                    </span>
                  </p>
                  <p>
                    {'- Отчет о работе по отказам в оказании государственных услуг: '}
                    <span className={styles.modalMonths}>
                      {report2AbsentMonths ? report2AbsentMonths.join(', ') : '-'}
                    </span>
                  </p>
                  <p>
                    {'- Отчет о выполнении мероприятий по государственным услугам: '}
                    <span className={styles.modalMonths}>
                      {report3AbsentMonths ? report3AbsentMonths.join(', ') : '-'}
                    </span>
                  </p>
                  <p>Пожалуйста создайте перечисленные отчеты в АРМ Госуслуги!</p>
                  <div className={styles.buttonWrapper}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                      onClick={() => this.setState({ isChecked: true })}
                    >
                      Ознакомлен
                    </Button>
                  </div>
                </div>
              )}
            </Modal>
          </Spin>
        );
      } else if (roleId !== 7 && roleId !== null || isChecked) {
        return <Redirect to="/list" />;
      }
    }
    return (
      <div style={{ width: '400px', margin: '40px auto' }}>
        <Form onSubmit={this.handleSubmit} className="login-form">
          <Form.Item>
            <Input
              value={this.state.login}
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Логин"
              onChange={(e) => this.inputOnChange('login', e)}
            />
          </Form.Item>
          <Form.Item>
            <Input
              value={this.state.password}
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              onChange={(e) => this.inputOnChange('password', e)}
              placeholder="Пароль"
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              onClick={() => this.sendLogin()}
            >
              Войти
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user, module } = state;
  return {
    user,
    module,
  };
}
export default withTranslation()(connect(mapStateToProps)(SingleLayout));
